<template>
	<header id="page-topbar">
		<div class="navbar-header">
			<div class="d-flex">
				<!-- LOGO -->
				<div class="navbar-brand-box">
					<router-link tag="a" to="/" class="logo logo-dark">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-dark.png" alt height="17" />
						</span>
					</router-link>

					<router-link tag="a" to="/" class="logo logo-light">
						<span class="logo-sm">
							<img src="@/assets/images/logo-sm.png" alt height="22" />
						</span>
						<span class="logo-lg">
							<img src="@/assets/images/logo-light.png" alt height="40" />
						</span>
					</router-link>
				</div>

				<button
					type="button"
					class="btn btn-sm px-3 font-size-24 header-item"
					id="vertical-menu-btn"
					@click="toggleMenu()"
				>
					<i class="mdi mdi-menu"></i>
				</button>
			</div>
		</div>
	</header>
</template>

<script>
/**
 * Topbar component
 */
export default {
	data() {
		return {};
	},
	methods: {
		toggleMenu() {
			this.$parent.toggleMenu();
		}
	}
};
</script>