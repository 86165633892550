<template>
	<div class="topnav">
		<div class="container-fluid">
			<nav class="navbar navbar-light navbar-expand-lg topnav-menu">
				<div 
					id="topnav-menu-content"
					class="collapse navbar-collapse"
				>
					<ul class="navbar-nav">
						<li 
							id="home-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/home"
							>
								<i class="ti-home mr-2" />Home
							</router-link>
						</li>
						<li 
							id="reports-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/reports"
							>
								<i class="ti-pie-chart mr-2" />Reports
							</router-link>
						</li>
						<li 
							id="projects-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/projects"
							>
								<i class="ti-folder mr-2" />Projects
							</router-link>
						</li>
						<li 
							id="time-entries-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/time-entries"
							>
								<i class="ti-timer mr-2" />Time Entries
							</router-link>
						</li>
						<li 
							id="tags-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/tags"
							>
								<i class="ti-tag mr-2" />Tags
							</router-link>
						</li>
						<li 
							id="settings-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/settings"
							>
								<i class="ti-settings mr-2" />Settings
							</router-link>
						</li>
						<li 
							id="support-nav-link"
							class="nav-item"
						>
							<router-link
								tag="a"
								class="nav-link side-nav-link"
								to="/client/support"
							>
								<i class="ti-support mr-2" />Support
							</router-link>
						</li>
						<li 
							id="logout-nav-link"
							class="nav-item"
						>
							<a 
								id="logoutButton"
								class="nav-link side-nav-link"
								@click="logout"
							>
								<i class="ion ion-md-exit mr-2" />Logout
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	</div>
</template>

<script>
import { layoutMethods } from "@/state/helpers";

/**
 * Horizontal navbar component
 */
export default {
	mounted() {
		var links = document.getElementsByClassName("side-nav-link");
		var matchingMenuItem = null;
		for (var i = 0; i < links.length; i++) {
			if (window.location.pathname === links[i].pathname) {
				matchingMenuItem = links[i];
				break;
			}
		}

		if (matchingMenuItem) {
			matchingMenuItem.classList.add("active");
			var parent = matchingMenuItem.parentElement;

			/**
			 * TODO: This is hard coded way of expanding/activating parent menu dropdown and working till level 3.
			 * We should come up with non hard coded approach
			 */
			if (parent) {
				parent.classList.add("active");
				const parent2 = parent.parentElement;
				if (parent2) {
					parent2.classList.add("active");
				}
				const parent3 = parent2.parentElement;
				if (parent3) {
					parent3.classList.add("active");
					var childAnchor = parent3.querySelector(".has-dropdown");
					if (childAnchor) childAnchor.classList.add("active");
				}

				const parent4 = parent3.parentElement;
				if (parent4) parent4.classList.add("active");
				const parent5 = parent4.parentElement;
				if (parent5) parent5.classList.add("active");
			}
		}
	},
	methods: {
		...layoutMethods,

		logout() {
			this.$store.dispatch('auth/logout')
		},

		/**
		 * menu clicked show the subelement
		 */
		onMenuClick(event) {
			event.preventDefault();
			const nextEl = event.target.nextSibling;
			if (nextEl && !nextEl.classList.contains("show")) {
				const parentEl = event.target.parentNode;
				if (parentEl) {
					parentEl.classList.remove("show");
				}
				nextEl.classList.add("show");
			} else if (nextEl) {
				nextEl.classList.remove("show");
			}
			return false;
		},
		topbarLight() {
			document.body.setAttribute("data-topbar", "light");
			document.body.removeAttribute("data-layout-size", "boxed");
		},
		boxedWidth() {
			document.body.setAttribute("data-layout-size", "boxed");
			document.body.removeAttribute("data-topbar", "light");
			document.body.setAttribute("data-topbar", "dark");
		},
		changeLayout(layout) {
			this.changeLayoutType({ layoutType: layout });
		}
	}
};
</script>

<style scoped>
#logoutButton:hover {
	cursor: pointer;
}
</style>